import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import {motion} from "framer-motion";
import {Swiper, SwiperSlide} from 'swiper/react';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Navigation, Pagination} from "swiper";

const Activities = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");

    return (
        <section id="activities" className="pt-10 pb-24">
            {/* HEADER AND IMAGE SECTION */}
            <div className="md:flex md:justify-between md:gap-16 mt-8 ">
                <motion.div
                    className="md:w-1/3"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{once: true, amount: 0.5}}
                    transition={{duration: 0.5}}
                    variants={{
                        hidden: {opacity: 0, x: -50},
                        visible: {opacity: 1, x: 0},
                    }}
                >
                    <p className="font-playfair font-semibold text-4xl mb-5 ">
                        <span className="text-green  ">Activities</span>
                    </p>
                    <LineGradient width="w-1/3"/>
                    <p className="mt-10 mb-7">In the following list, you will find some events and webinars where I have
                        given a talk</p>
                </motion.div>

                <div className="mt-16 md:mt-0">
                    {isAboveLarge ? (
                        <div
                            className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10"
                        >
                            <img
                                alt="activity"
                                className="rounded-full max-w-[400px] border-solid opacity-50 hover:opacity-100"
                                src="assets/activity.jpeg"
                            />
                        </div>
                    ) : (
                        <img alt="skills"
                             className="rounded-full max-w-[300px] border-solid opacity-50 hover:opacity-100 justify-center"
                             src="assets/activity.jpeg"/>
                    )}
                </div>
            </div>

            {/* SKILLS */}
            {isAboveLarge ? (
                <Swiper
                    spaceBetween={40}
                    slidesPerView={1}
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}

                    className="mySwiper text-sm"

                >
                    <SwiperSlide>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            className="	ml-24"

                            viewport={{once: true, amount: 0.5}}
                            transition={{duration: 0.5}}


                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-2xl hover:cursor-pointer"
                                       onClick={(event) => window.location.href = 'https://www.paloaltonetworks.com/blog/prisma-cloud/api-security-google-cloud-service-extensions/'}
                                    >API Security with GCP Service Extensions</p>
                                    <p className="font-playfair s:font-opensans  text-sm mt-3  ">
                                        Google Cloud Next'24, Las Vegas, April,2024
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm">
                                During this presentation, I will demonstrate how to use the Google Service Extension Beta feature to inspect all HTTP traffic data from the Google Application Load Balancer. <br/>
                                We will cover how to identify and find HTTP attacks, and then send the insights gained to Palo Alto Prisma Cloud. <br/>
                                Additionally, I will show a real demo use case of a development called "panw apisec sensor" that inspects all the HTTP traffic data of the load balancer and learns from it.

                                <br/>
                                <br/>
                                Session Outline:
                                <br/>
                                - Overview on API Security<br/>
                                - Why API Security is important<br/>
                                - How to use GCP Service extension for API Security<br/>
                                - 4 levels for API Security<br/>
                                <br/>
                            </p>
                            <img src="assets/gnext.jpeg" alt="odsceurope"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights-2/data'}

                                 className=" max-w-[400px] border-solid opacity-50 hover:opacity-100 hover:cursor-pointer"/>

                        </motion.div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            className="	ml-24"

                            viewport={{once: true, amount: 0.5}}
                            transition={{duration: 0.5}}


                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-2xl hover:cursor-pointer"
                                       onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights-2/data'}
                                    >Data-driven retraining with
                                        production
                                        ML insights</p>
                                    <p className="font-playfair s:font-opensans  text-sm mt-3  ">
                                        ODSC West, San Fransisco, Nov,2022
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm">
                                In this talk, I show case, through ML monitoring and notebooks, how data scientists and
                                ML engineers can leverage ML monitoring to find the best data and retraining strategy
                                mix to resolve machine learning performance issues. This data-driven, production-first
                                approach enables more thoughtful retraining selections, shorter and leaner retraining
                                cycles, and can be integrated into MLOps CI/CD pipelines for continuous model retraining
                                upon anomaly detection
                                <br/>
                                <br/>
                                Session Outline:
                                <br/>
                                - Retraining groups and temporal similarity<br/>
                                - Drifted features and pre-preprocessing<br/>
                                - Drifted segments and model split<br/>
                                - Pipeline anomaly exclusion.<br/>
                                <br/>
                            </p>
                            <img src="assets/odscwest.jpeg" alt="odsceurope"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights-2/data'}

                                 className=" max-w-[400px] border-solid opacity-50 hover:opacity-100 hover:cursor-pointer"/>

                        </motion.div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 0.5}}
                            className="ml-24"
                            transition={{duration: 0.5}}
                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-2xl hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://info.datascience.salon/automating-ml-pipelines-with-production-first-data'}>Automating ML pipelines</p>
                                    <p className="font-playfair s:font-opensans text-sm mt-3  ">
                                        Data science Salon, Virtual Event, June,2022
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm">
                                As teams look to build and deploy models into production, they need tools that can
                                adequately scale with them. In particular, the tools they need must allow them to
                                quickly monitor, segment, retrain, and experiment on the data.<br/>
                                <br/>Session Outline:<br/>
                                - What is MLOps and why is data critical for it./<br/>
                                - How to architect a scalable and automated platform.<br/>
                                - Why your team should adopt a Production-First data approach.<br/>
                            </p>
                            <br/>
                            <img src="assets/pachyderm.jpeg" alt="ml pipelines" onClick={(event) => window.location.href = 'https://info.datascience.salon/automating-ml-pipelines-with-production-first-data'}                                 className="hover:cursor-pointer max-w-[400px] border-solid opacity-50 hover:opacity-100"/>

                        </motion.div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <motion.div

                            initial="hidden"
                            whileInView="visible"
                            className=" ml-24	"

                            viewport={{once: true, amount: 0.5}}
                            transition={{duration: 0.5}}
                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-2xl hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights/data'}>Data-driven retraining with
                                        production
                                        ML insights</p>
                                    <p className="font-playfair s:font-opensans  text-sm mt-3  ">
                                        ODSC Europe, London, May 2022
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm  ">
                                It’s practically dogma today that a model's best day in production will be its first day
                                in production. Over time model performance degrades, and thare are many variables that
                                can cause decay, from real-world behavior changes to data drifts.<br/> When models
                                misbehave, we often turn to retraining to fix the problem, but is the most recent data
                                the best data to resolve our model performance issues and get it back on track? We all
                                acknowledge the need for data-driven machine learning monitoring that pinpoints
                                anomalies and uncovers their root cause so we can resolve issues quickly before they
                                impact the business.<br/> When it comes to resolution through retraining, data selection
                                and the retraining strategy selected are less than data-driven. Today when faced with
                                retraining, many data teams simply select the last month or two of data to retrain on
                                and hope that fresh really is best.
                            </p>
                            <br/>
                            <img src="assets/odsceurope.jpeg" alt="odsc"
                                 className="max-w-[400px] border-solid opacity-50 hover:opacity-100 hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights/data'}
                            />

                        </motion.div>
                    </SwiperSlide>

                </Swiper>) : (
                <Swiper
                    spaceBetween={200}
                    slidesPerView={1}
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}

                    className="mySwiper text-sm"

                >
                    <SwiperSlide>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            className="ml-14 mt-8"

                            viewport={{once: true, amount: 0.5}}
                            transition={{duration: 0.5}}


                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-sm hover:cursor-pointer	"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights-2/data'}
                                    >Data-driven retraining with
                                        production
                                        ML insights</p>
                                    <p className="font-playfair s:font-opensans  text-sm mt-3  ">
                                        ODSC West, San Fransisco, Nov,2022
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm">
                                In this talk, I show case, through ML monitoring and notebooks, how data scientists and
                                ML engineers can leverage ML monitoring to find the best data and retraining strategy
                                mix to resolve machine learning performance issues. This data-driven, production-first
                                approach enables more thoughtful retraining selections, shorter and leaner retraining
                                cycles, and can be integrated into MLOps CI/CD pipelines for continuous model retraining
                                upon anomaly detection
                                <br/>
                                <br/>
                                Session Outline:
                                <br/>
                                - Retraining groups and temporal similarity<br/>
                                - Drifted features and pre-preprocessing<br/>
                                - Drifted segments and model split<br/>
                                - Pipeline anomaly exclusion.<br/>
                                <br/>
                            </p>
                            <img src="assets/odscwest.jpeg" alt="odsceurope"
                                 className=" max-w-[200px] border-solid opacity-50 hover:opacity-100 hover:cursor-pointer	"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights-2/data'}
                            />

                        </motion.div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 0.5}}
                            className="	ml-14 mt-8"
                            transition={{duration: 0.5}}
                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-sm hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://info.datascience.salon/automating-ml-pipelines-with-production-first-data'}
                                    >Automating ML pipelines</p>
                                    <p className="font-playfair s:font-opensans text-sm mt-3  ">
                                        Data science Salon, Virtual Event, June,2022
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm">
                                As teams look to build and deploy models into production, they need tools that can
                                adequately scale with them. In particular, the tools they need must allow them to
                                quickly monitor, segment, retrain, and experiment on the data.<br/>
                                <br/>Session Outline:<br/>
                                - What is MLOps and why is data critical for it./<br/>
                                - How to architect a scalable and automated platform.<br/>
                                - Why your team should adopt a Production-First data approach.<br/>
                            </p>
                            <br/>
                            <img src="assets/pachyderm.jpeg" alt="ml pipelines"
                                 className="max-w-[200px] border-solid opacity-50 hover:opacity-100 hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://info.datascience.salon/automating-ml-pipelines-with-production-first-data'}
                            />

                        </motion.div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <motion.div

                            initial="hidden"
                            whileInView="visible"
                            className=" ml-14	mt-8"

                            viewport={{once: true, amount: 0.5}}
                            transition={{duration: 0.5}}
                            variants={{
                                hidden: {opacity: 0, y: 50},
                                visible: {opacity: 1, y: 0},
                            }}
                        >
                            <div className="relative h-32">

                                <div className="z-10">

                                    <p className="font-playfair font-semibold text-sm hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights/data'}
                                    >Data-driven retraining with
                                        production
                                        ML insights</p>
                                    <p className="font-playfair s:font-opensans  text-sm mt-3  ">
                                        ODSC Europe, London, May 2022
                                    </p>

                                </div>

                            </div>
                            <p className="text-sm p-4 ">
                                It’s practically dogma today that a model's best day in production will be its first day
                                in production. Over time model performance degrades, and thare are many variables that
                                can cause decay, from real-world behavior changes to data drifts.<br/> When models
                                misbehave, we often turn to retraining to fix the problem, but is the most recent data
                                the best data to resolve our model performance issues and get it back on track? We all
                                acknowledge the need for data-driven machine learning monitoring that pinpoints
                                anomalies and uncovers their root cause so we can resolve issues quickly before they
                                impact the business.<br/> When it comes to resolution through retraining, data selection
                                and the retraining strategy selected are less than data-driven. Today when faced with
                                retraining, many data teams simply select the last month or two of data to retrain on
                                and hope that fresh really is best.
                            </p>
                            <br/>
                            <img src="assets/odsceurope.jpeg" alt="odsc"
                                 className="max-w-[200px] border-solid opacity-50 hover:opacity-100 hover:cursor-pointer"                             onClick={(event) => window.location.href = 'https://odsc.com/speakers/data-driven-ml-retraining-with-production-insights/data'}
                            />

                        </motion.div>
                    </SwiperSlide>

                </Swiper>)
            }
        </section>
    );
};

export default Activities;
