import Navbar from "./scenes/Navbar";
import Landing from "./scenes/Landing";
import DotGroup from "./scenes/DotGroup";
import Activities from "./scenes/Activities";
import LineGradient from "./components/LineGradient";
import Blogs from "./scenes/Blogs";
import Contact from "./scenes/Contact";
import Footer from "./scenes/Footer";
import useMediaQuery from "./hooks/useMediaQuery";
import {useEffect, useState} from "react";
import {motion} from "framer-motion";
import React from "react";

function App() {
    const [selectedPage, setSelectedPage] = useState("home");
    const [isTopOfPage, setIsTopOfPage] = useState(true);
    const isDesktop = useMediaQuery("(min-width: 1060px)");

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsTopOfPage(true);
                setSelectedPage("home");
            }
            if (window.scrollY !== 0) setIsTopOfPage(false);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="app bg-deep-blue">
            <Navbar
                isTopOfPage={isTopOfPage}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
            />
            <div className="w-5/6 mx-auto md:h-full mb-64">
                {isDesktop && (
                    <DotGroup
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                )}
                <motion.div
                    margin="0 0 -200px 0"
                    amount="all"
                    onViewportEnter={() => setSelectedPage("home")}

                >
                    <Landing setSelectedPage={setSelectedPage}/>
                </motion.div>
            </div>
            <div className="mt-48">
                <LineGradient/>
            </div>
            <div className="w-5/6 mx-auto md:h-full mb-64 ">
                <motion.div
                    margin="0 0 -200px 0"
                    amount="all"
                    onViewportEnter={() => setSelectedPage("activities")}
                >


                    <Activities/>
                </motion.div>
            </div>

            <LineGradient />

            <div className="w-5/6 mx-auto md:h-full mb-64">
                <motion.div
                    margin="0 0 -400px 0"
                    amount="all"
                    onViewportEnter={() => setSelectedPage("blogs")}
                >
                    <Blogs/>
                </motion.div>
            </div>

            <LineGradient/>
            <div className="w-5/6 mx-auto md:h-full mb-48">
                <motion.div
                    margin="0 0 -200px 0"
                    amount="all"
                    onViewportEnter={() => setSelectedPage("contact")}
                >
                    <Contact/>
                </motion.div>
            </div>

            <Footer className="w-5/6 mx-auto md:h-full"/>

        </div>
    );
}

export default App;
