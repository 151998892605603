import LineGradient from "../components/LineGradient";
import {motion} from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";

const container = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const projectVariant = {
    hidden: {opacity: 0, scale: 0.8},
    visible: {opacity: 1, scale: 1},
};

const Blog = ({title, description, image, blogURL}) => {
    const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`;
    const overlayStylesMobile = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-10 flex flex-col justify-center items-center text-center p-2 text-deep-blue`;
    const projectTitle = title.split(" ").join("-").toLowerCase();
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");

    const redirectToBlog = (event) => {
        console.log("redirectToBlog");
        window.location.href = blogURL;
    }
    return (
        <div>
            {
                isAboveLarge ? (
                    <motion.div variants={projectVariant} className="relative hover:cursor-pointer"
                                onClick={() => redirectToBlog()}>
                        <div className={overlayStyles}>
                            <p className="text-l font-playfair">{title}</p>
                            <p className="mt-7 text-sm">
                                {description}
                            </p>
                        </div>
                        <img src={`../assets/${image}`} alt={projectTitle}/>
                    </motion.div>) : (
                    <motion.div variants={projectVariant} className="relative hover:cursor-pointer"
                                onClick={() => redirectToBlog()}>
                        <div className={overlayStylesMobile}>
                            <p className="text-sm font-semibold">{title}</p>
                            <p className="mt-2 text-sm">
                                {description}
                            </p>
                        </div>
                        <img src={`../assets/${image}`} alt={projectTitle}/>
                    </motion.div>)
            }
        </div>
    );
};

const Blogs = () => {
    return (
        <section id="blogs" className="pt-10 pb-48">
            {/* HEADINGS */}
            <motion.div
                className="md:w-1/5 mx-auto text-center"
                initial="hidden"
                whileInView="visible"
                viewport={{once: true, amount: 0.5}}
                transition={{duration: 0.5}}
                variants={{
                    hidden: {opacity: 0, y: -50},
                    visible: {opacity: 1, y: 0},
                }}
            >
                <div>
                    <p className="font-playfair font-semibold text-4xl">
                        <span className="text-green">Blogs</span>
                    </p>
                    <div className="flex justify-center mt-5">
                        <LineGradient width="w-2/3"/>
                    </div>
                </div>
                <p className="mt-10 mb-10">
                    A few of my published articles can be found here.</p>
            </motion.div>

            {/* PROJECTS */}

            <div className="flex justify-center">
                <motion.div
                    className="sm:grid sm:grid-cols-3"
                    variants={container}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{once: true, amount: 0.2}}
                >
                    {/* ROW 1 */}
                    <Blog title="Data-driven retraining with production observability insights"
                          description="“Refreshing” a model’s training observations isn't always enough to predict future results better. Using observability of model production data gives us a clear path to better decisions on our retraining strategies."
                          blogURL="https://superwise.ai/blog/data-driven-retraining-with-production-observability-insights"
                          image="data-driven.png"/>
                    <Blog title="So you want to be API-first?"
                          description="Building a great, scalable, and easy-to-integrate product starts with design. At superwise, we understand the importance of being an API-first company. Read my article and follow superwise’s journey to API-first and what considerations you should make before embarking on that journey."
                          image="So-you-want-to-be-API-first.png"
                          blogURL="https://superwise.ai/blog/so-you-want-to-be-api-first/"/>
                    <Blog title="Testing Data-driven Microservices"
                          blogURL="https://pub.towardsai.net/testing-data-driven-microservices-a56a77d839b7"
                          description="Testing microservices that work on a massive data scale is a complex task. I designed an efficient architecture to make the testing phase much easier for writing, debugging and ensuring the application integrity (Hint: It leverages Jupyter Notebook and Papermill)"
                          image="testing_microservices.png"/>
                    <Blog title="Use FastAPI for Better Machine Learning in Production"
                          blogURL="https://betterprogramming.pub/fastapi-for-better-ml-in-production-358e555d9ca3?gi=d217eb79d940"
                          description="Deploying ML for serving in production sounds like a complicated task, which costs too much times for developers. Check out my article to understand why you should start using FastAPI to speed up this process and save a lot of time."
                          image="fastapi.png"/>
                    <Blog title="Scaling data-driven microservices"
                          description="Scaling data-driven microservices need to be supported by an infrastructure that can accommodate resource extension or decrease resources, but more importantly, codes should be written to support scalability"
                          blogURL="https://towardsdatascience.com/scaling-data-driven-microservices-27be1f5df34a"
                          image="scaling_microservices.png"/>


                </motion.div>
            </div>
        </section>
    );
};

export default Blogs;
