import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Landing = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="home"
      className="md:flex md:justify-between md:items-center gap-16 md:h-full py-10"
    >
      {/* IMAGE SECTION */}
      <div className="basis-3/5 z-10 mt-16 md:mt-32 flex justify-center md:order-2">
        {isAboveLarge ? (
          <div
            className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-20 before:rounded-t-[400px]"
          >
            <img
              alt="profile"
              className="hover:filter hover:opacity-100 opacity-50 transition duration-500 z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full border-solid"
              src="assets/oryan.jpeg"
            />
          </div>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full  hover:opacity-100 opacity-50"
            src="assets/oryan.jpeg"
          />
        )}
      </div>

      {/* MAIN TEXT */}
      <div className="z-30 basis-2/5 mt-12 md:mt-32">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="text-6xl font-playfair z-10 text-center md:text-start">
            {""}
            <span
              className="xs:relative xs:text-deep-blue xs:font-semibold z-20 xs:before:content-brush
              before:absolute before:-left-[25px] before:-top-[70px] before:z-[-1] "
            >
              Oryan Omer
            </span>
          </p>

          <p className="mt-10 mb-7 text-xl text-center md:text-start">
            Principal Software Engineer 👨🏻‍💻 | MLOps Engineer 🤖 | ML Engineer 🤖 | Cyber Security Specialist | Wave Surfer 🌊
          </p>
          <p className="mt-3 mb-7 text-sm text-center md:text-start">
            Principal Software Engineer with a strong expertise in machine learning, MLOps,
            and Cloud Security. <br/> With 10 years of experience developing services for both production
            and development environments, I have a proven track record of delivering high-quality
            software solutions. <br/>
            Throughout my career, I have led teams, mentored junior and senior developers,
            improved R&D processes, designed complex architecture solutions, and managed
            large-scale projects. <br/> Additionally, I have been a speaker at several conferences such as
            ODSC West(SF) and Europe(London) in 2022, Google Next 24 in Las Vegas, and have also
            participated in various webinars.
            <br/>
            <br/>
            I frequently publish content on Linkedin and Medium that talks about ML engineering, MLOps, Software engineering, and tech in general.
            <br/>
            <br/>
            I have a B.sc computer science from the College of Management.
            <br/>
            <br/>
            In my free time, I'm surfing 🌊, snowboarding 🏂 and doing a lot of sports (:
          </p>
        </motion.div>

        {/* CALL TO ACTIONS */}
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
            onClick={() => setSelectedPage("contact")}
            href="#contact"
          >
            Contact Me
          </AnchorLink>

        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
